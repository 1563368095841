.letterDefinitions {
  height: 0;
}

.grid {
  position: fixed;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
  background-repeat: repeat-x;

  left: 1rem;
  margin-left: 0;
  width: calc(100% - 2rem);

  columns: 12;
  gap: 0.5rem;

  & > * {
    background: dodgerblue;
    height: 100%;
  }
}

@screen sm {
  .grid {
    left: 50%;
    width: calc(theme("screens.sm") - 2 * theme("container.padding.sm"));
    margin-left: calc(
      (theme("screens.sm") - 2 * theme("container.padding.sm")) / -2
    );
    gap: theme("container.grid.sm.gap");
  }
}

@screen md {
  .grid {
    width: calc(theme("screens.md") - 2 * theme("container.padding.md"));
    margin-left: calc(
      (theme("screens.md") - 2 * theme("container.padding.md")) / -2
    );
    gap: theme("container.grid.md.gap");
  }
}

@screen lg {
  .grid {
    width: calc(theme("screens.lg") - 2 * theme("container.padding.lg"));
    margin-left: calc(
      (theme("screens.lg") - 2 * theme("container.padding.lg")) / -2
    );
    gap: theme("container.grid.lg.gap");
  }
}

@screen xl {
  .grid {
    width: calc(theme("screens.xl") - 2 * theme("container.padding.xl"));
    margin-left: calc(
      (theme("screens.xl") - 2 * theme("container.padding.xl")) / -2
    );
    gap: theme("container.grid.xl.gap");
  }
}

@screen 2xl {
  .grid {
    width: calc(theme("screens.2xl") - 2 * theme("container.padding.2xl"));
    margin-left: calc(
      (theme("screens.2xl") - 2 * theme("container.padding.2xl")) / -2
    );
    gap: theme("container.grid.2xl.gap");
  }
}

@screen 3xl {
  .grid {
    width: calc(theme("screens.3xl") - 2 * theme("container.padding.3xl"));
    margin-left: calc(
      (theme("screens.3xl") - 2 * theme("container.padding.3xl")) / -2
    );
    gap: theme("container.grid.3xl.gap");
  }
}
