.header {
  @apply w-full bg-black;
  @apply sticky top-0 left-0 z-50 w-full py-6;
}

.inner {
  @apply container;
  @apply flex items-center justify-between;
}

.logo {
  svg {
    width: auto;
    height: 57px;

    path {
      @apply fill-berry;
    }
  }

  :global(.menu-open) & {
    @apply static;
  }
}

.nav {
  @apply flex items-center relative;
}

.burger {
  @apply cursor-pointer;
  @apply block xl:hidden;
}

@screen xl {
  .nav {
    position: sticky;
    top: 0;
    right: 0;

    height: 4rem;

    &::before {
      width: 4rem;
      height: 4rem;
      transform: translate3d(-3.2rem, 0, 0) scaleY(-1);
    }
  }

  .burger {
    @apply hidden;
  }
}
