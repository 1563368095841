.navigation {
  @apply hidden xl:flex items-center gap-2 relative;

  a {
    @apply relative overflow-hidden text-base py-2 px-5;
    @apply uppercase no-underline tracking-wider box-border text-white;

    @apply hidden;
    @screen xl {
      @apply flex;
    }

    &:first-child {
      margin-left: 2rem;
    }
    &:last-child {
      margin-right: 2rem;
    }

    &:hover,
    &:focus {
      @apply bg-neutral-800 rounded-[24px];
    }
  }

  div {
    @apply inline-block p-1 cursor-pointer;
  }
}
